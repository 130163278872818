// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/home/scoutuser/scout-frontend/src/pages/List/model';
import model_2 from '/home/scoutuser/scout-frontend/src/pages/Search/people/model';
import model_3 from '/home/scoutuser/scout-frontend/src/pages/Search/products/model';

export const models = {
model_1: { namespace: 'List.model', model: model_1 },
model_2: { namespace: 'Search.people.model', model: model_2 },
model_3: { namespace: 'Search.products.model', model: model_3 },
} as const

import type { Settings as LayoutSettings } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { history } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { currentUser } from './pages/User/Login/service';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { motion } from 'framer-motion';
import { loginOut } from './components/RightContent/SideBarBottom';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiG9qdW_tnXjyyqzobxEcaeBmD64BZJQg",
  authDomain: "scout-e78e3.firebaseapp.com",
  projectId: "scout-e78e3",
  storageBucket: "scout-e78e3.appspot.com",
  messagingSenderId: "506508788463",
  appId: "1:506508788463:web:fd5e170891958dcf27d11a",
  measurementId: "G-9YLCJ9X56N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const loginPath = '/user/login';

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: any;
  loading?: boolean;
  fetchUserInfo?: () => Promise<any>;
}> {

  const fetchUserInfo = async () => {

    try {
      const msg = await currentUser();

      return msg;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  const { location } = history;
  if (location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings as Partial<LayoutSettings>,
    };
  }
  return {
    fetchUserInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }: any) => {
  const [isHovered, setIsHovered] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      const sider = document.querySelector(".ant-layout-sider");
      if (sider) {
        const hovered = sider.matches(":hover");
        if (hovered && !isHovered) {
          console.log("Hovered over sider");
          setIsHovered(true);
          setCollapsed(false); // Uncollapse when hovered
        } else if (!hovered && isHovered) {
          console.log("Unhovered from sider");
          setIsHovered(false);
          setCollapsed(true); // Collapse when unhovered
        }
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [isHovered]);

  const svgVariants = {
    open: {
      width: 120,
      height: 40,
      viewBox: "0 0 920 259"
    },
    closed: {
      width: 50,
      height: 50,
      viewBox: "0 0 200 259"
    }
  }

  return {
    actionsRender: () => [],
    onCollapse: (collapsed: boolean) => {
      setCollapsed(collapsed);
    },
    siderWidth: 220,
    title: false,
    collapsed: collapsed,
    defaultCollapsed: true,
    breakpoint: false,
    className: 'layout-root',
    collapsedButtonRender: false,
    logo: <motion.svg
      animate={collapsed ? 'closed' : 'open'}
      variants={svgVariants}
      transition={{ duration: 0.1, ease: 'circInOut' }}
      version="1.0" xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,259.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M1175 2510 c-109 -15 -188 -40 -293 -92 -56 -28 -105 -49 -108 -46
 -3 3 15 29 40 57 46 52 52 62 40 74 -11 11 -100 -40 -164 -93 -77 -65 -139
 -143 -190 -242 -44 -84 -67 -106 -175 -162 -38 -20 -92 -53 -119 -72 l-49 -36
 7 -152 c3 -83 11 -182 16 -221 6 -38 13 -101 16 -140 6 -68 4 -72 -31 -130
 -74 -121 -88 -156 -93 -236 -12 -206 125 -394 302 -414 98 -11 121 -25 141
 -84 22 -67 87 -126 299 -269 266 -180 371 -221 570 -222 137 0 209 20 264 73
 96 94 201 280 241 427 28 103 49 256 61 440 13 206 30 260 95 303 19 12 35 27
 35 33 0 7 -11 32 -25 55 -14 24 -25 55 -25 68 0 14 25 57 60 103 87 116 92
 178 20 280 -22 31 -40 60 -40 63 0 12 47 -20 97 -66 79 -74 93 -66 93 50 0
 108 -25 178 -98 268 -18 23 -32 50 -30 59 2 10 32 40 68 67 36 27 66 53 68 57
 9 23 -230 76 -471 106 -47 5 -48 6 -45 36 5 51 -37 60 -297 64 -121 1 -247 -1
 -280 -6z m-158 -535 c34 -8 101 -30 150 -49 217 -87 320 -94 487 -37 183 63
 240 31 293 -169 18 -67 25 -261 10 -276 -4 -4 -76 -10 -160 -13 -83 -4 -158
 -10 -165 -14 -82 -53 -123 -519 -60 -698 19 -55 28 -16 13 56 -13 62 -19 125
 -12 125 1 0 24 -11 51 -25 29 -15 66 -25 90 -25 43 0 117 29 150 58 18 17 19
 15 13 -88 -6 -116 -41 -269 -87 -380 -46 -112 -165 -286 -214 -315 -87 -51
 -282 -51 -412 0 -105 42 -529 315 -566 366 -10 14 -23 53 -28 88 -5 35 -11 65
 -14 68 -2 3 -38 6 -78 8 -224 10 -363 159 -344 369 14 160 132 285 239 255 55
 -15 84 -55 102 -139 13 -63 21 -80 50 -107 60 -57 61 -54 69 154 l6 187 40 51
 c21 28 53 61 69 73 36 26 39 51 16 126 -23 75 -19 162 9 221 23 48 79 110 121
 133 28 16 89 14 162 -3z m922 -729 c7 -5 4 -20 -9 -47 l-20 -39 -43 22 c-37
 20 -57 23 -159 23 -104 0 -118 2 -118 17 0 9 8 22 18 27 18 12 312 8 331 -3z
 m-63 -152 c23 -34 21 -99 -3 -132 -26 -36 -101 -81 -144 -88 -22 -4 -49 0 -74
 11 l-40 18 61 11 c76 15 106 34 129 82 17 36 17 95 0 138 -8 17 -6 17 23 2 18
 -9 39 -28 48 -42z m-120 -49 c10 -24 -22 -62 -62 -75 -58 -19 -65 1 -18 49 41
 41 71 51 80 26z"/>
        <path d="M858 1508 c-15 -12 -148 -184 -148 -192 0 -2 75 -6 168 -9 92 -3 217
 -13 277 -21 214 -30 182 -36 245 44 59 75 68 90 51 90 -6 0 -130 23 -276 50
 -293 55 -294 55 -317 38z"/>
        <path d="M965 1280 c-65 -14 -114 -38 -161 -82 -41 -38 -44 -44 -44 -91 0 -70
 18 -121 59 -168 47 -56 119 -89 194 -89 73 0 74 15 1 24 -74 8 -114 26 -158
 70 -39 40 -59 80 -68 137 -11 64 7 66 36 3 33 -72 59 -101 111 -125 53 -25
 101 -23 150 4 49 27 85 93 85 155 0 39 2 43 16 31 8 -7 24 -30 35 -50 19 -38
 51 -64 63 -52 12 12 6 54 -13 91 -25 48 -87 107 -131 125 -47 20 -126 27 -175
 17z m147 -205 c-5 -29 -18 -41 -68 -65 -44 -22 -89 -20 -81 3 2 7 19 19 38 28
 19 9 43 27 53 38 25 27 62 25 58 -4z"/>
        <path d="M1051 574 c-23 -29 -27 -60 -11 -84 7 -11 10 -5 10 23 0 28 7 44 26
 62 20 18 23 25 11 25 -8 0 -25 -12 -36 -26z"/>
        <path d="M1087 543 c-18 -18 -1 -34 66 -61 137 -57 311 -76 432 -49 41 10 51
 16 53 36 3 17 0 22 -10 19 -117 -45 -325 -29 -475 37 -63 29 -58 27 -66 18z"/>

        {/* THE WORD "S C O U T" starts */}
        <path d="M3008 2134 c-118 -28 -239 -109 -294 -196 -48 -75 -67 -155 -67 -273
 0 -177 41 -284 147 -382 82 -76 166 -112 423 -184 282 -78 339 -108 377 -201
 25 -58 21 -174 -6 -228 -58 -114 -256 -173 -410 -122 -152 50 -268 211 -268
 369 l0 53 -125 0 -125 0 0 -335 0 -336 128 3 127 3 -3 57 c-2 32 -1 58 3 58 4
 0 25 -15 48 -33 93 -74 211 -121 332 -132 271 -25 530 162 610 440 26 89 17
 257 -19 345 -36 90 -101 169 -183 222 -92 61 -139 79 -343 133 -364 97 -440
 145 -440 280 0 94 41 153 129 186 126 47 258 33 381 -41 108 -64 154 -131 148
 -214 l-3 -41 123 -3 122 -3 0 286 0 285 -120 0 -120 0 0 -50 0 -49 -77 40
 c-108 55 -212 79 -337 78 -56 0 -127 -7 -158 -15z"/>
        <path d="M8833 1932 c-99 -79 -172 -153 -183 -188 -7 -19 -10 -65 -8 -102 l3
 -67 -75 -5 -75 -5 0 -110 0 -110 78 -3 77 -3 1 -272 c0 -150 4 -354 8 -454 7
 -181 7 -183 36 -225 18 -25 50 -52 80 -67 46 -24 60 -26 175 -25 72 0 137 5
 153 12 l27 12 0 109 0 110 -78 -6 c-73 -5 -80 -4 -107 20 l-30 25 -3 375 -3
 374 53 6 c29 4 81 7 116 7 l62 0 0 110 0 110 -27 1 c-16 1 -70 1 -120 -1 l-93
 -2 0 200 c0 111 -4 203 -9 206 -5 4 -31 -11 -58 -32z"/>
        <path d="M4510 1576 c-141 -42 -221 -97 -294 -199 -104 -147 -146 -276 -146
 -446 1 -273 120 -486 331 -590 102 -50 193 -71 320 -71 178 0 313 49 430 158
 67 62 159 186 159 214 0 19 -202 117 -207 100 -9 -26 -96 -133 -131 -162 -70
 -57 -117 -74 -216 -74 -81 0 -98 3 -157 31 -119 55 -188 142 -223 279 -56 216
 26 423 204 514 55 29 196 39 256 20 96 -31 171 -115 201 -222 l16 -58 41 0
 c23 0 71 3 109 7 l67 6 0 239 0 239 -112 -3 -113 -3 -3 -31 -3 -31 -72 38
 c-40 20 -100 44 -133 53 -91 24 -232 20 -324 -8z"/>
        <path d="M6025 1594 c-11 -2 -45 -9 -75 -15 -205 -40 -399 -223 -465 -439 -25
 -84 -32 -289 -11 -370 45 -180 173 -339 336 -418 123 -61 194 -77 330 -76 97
 1 128 5 196 27 248 82 428 330 441 612 10 190 -47 337 -181 471 -133 132 -280
 199 -458 208 -51 3 -102 3 -113 0z m212 -280 c117 -32 235 -145 268 -258 44
 -146 -17 -343 -133 -435 -84 -65 -118 -76 -237 -76 -99 0 -116 3 -169 28 -122
 57 -202 149 -232 267 -54 212 108 461 318 489 63 9 115 4 185 -15z"/>

        <path d="M6957 1553 c-4 -3 -7 -53 -7 -110 l0 -103 78 0 c42 0 82 -4 89 -8 8
 -5 13 -111 15 -387 4 -363 5 -383 27 -449 70 -211 302 -276 560 -155 35 16 86
 45 113 64 26 19 50 35 53 35 3 0 5 -29 5 -65 l0 -65 235 0 236 0 -3 113 -3
 112 -102 3 -103 3 0 157 c0 86 -3 316 -7 510 l-6 352 -234 0 -233 0 0 -109 0
 -108 51 -6 c29 -4 78 -4 110 0 l59 6 0 -330 c0 -329 0 -330 -22 -350 -35 -32
 -161 -111 -213 -133 -57 -25 -153 -27 -196 -4 -62 32 -63 39 -67 562 l-4 472
 -212 0 c-117 0 -216 -3 -219 -7z"/>

      </g>
    </motion.svg>
    ,
    logoStyle: {
      backgroundColor: 'yellow',
      display: 'flex',
      justifyContent: 'center',
    },
    avatarProps: {
      src: initialState?.currentUser?.avatar,
      title: <Button type='text' onClick={loginOut}>Log Out</Button>,
    },
    menuContentRender: (props, defaultDom) => {
      return <>
        {defaultDom}
      </>
    },
    onPageChange: () => {
      const { location } = history;
      if (!initialState?.currentUser && location.pathname !== loginPath) {
        history.push(loginPath);
      }
    },

    childrenRender: (children: any) => {
      return (
        <>
          {children}
        </>
      );
    },
    ...initialState?.settings,
  };
};

export const request = {
  ...errorConfig,
};
import { message } from "antd";
import { fetchProduct, fetchProductsByIngredient, fetchProductLists, createProductList, addProductsToList, autoCompleteIngredientName } from "./service";

const Model = {
    namespace: 'search',
    state: {
        searchValue: '',
        filters: {},
        productsData: {
            data: [],
            total: 0
        },
        productLists: [],
        selectedRows: [],
        ingredientSuggestions: [],
        filterDrawerVisible: false,
        listModalVisible: false
    },
    effects: {
        *ingredientAutoComplete({ payload, callback }: any, { call, put }: any) {
            try {
                yield put({ type: 'setIngredientSuggestions', payload: [] });
                const response: Promise<any> = yield call(autoCompleteIngredientName, payload);
                yield put({ type: 'setIngredientSuggestions', payload: response });
            } catch (error) {
                console.error('Failed to fetch suggestions:', error);
                // Handle error appropriately here
            } finally {
                if (callback) callback();
            }
        },        
        *fetchProductsByIngredient({ payload }: any, { call, put, select }: any) {
            const searchState: Promise<any> = yield select((state: any) => state.search)
            const { searchValue, filters } = searchState as any

            let _searchValue = searchValue

            if(payload != null && payload != undefined && payload.trim().length > 0) _searchValue = payload
            
            const response: Promise<any> = yield call(fetchProductsByIngredient, {
                take: 16,
                skip: 0,
                searchTerm: _searchValue,
                filters
            });

            yield put({
                type: `resetIngredientSuggestion`
            })

            yield put({
                type: 'setProducts',
                payload: response
            })
        },
        *fetchProduct({ payload, callback }: any, { call, put, select }: any) {
            const product: Promise<any> = yield call(fetchProduct, payload)

            let _productData: Promise<any> = yield select((state: any) => state.search.productsData)
            _productData = _productData as any
            // replace obfuscated row with the actual product data
            (_productData as any).data = (_productData as any).data.map((row: any, index: number) => {
                if (row.id === (product as any).id) {
                    return {
                        ...product,
                        countries_of_sale: (product as any).countries_of_sale.map((c: any) => c.name).join(", ")
                    }
                }
                return row
            })


            yield put({
                type: 'setProducts',
                payload: _productData
            })

            if (callback) callback()
        },
        *fetchProductLists(_: any, { call, put }: any) {
            const response: Promise<any> = yield call(fetchProductLists)
            yield put({
                type: 'setProductLists',
                payload: response
            })
        },
        *createProductList({ payload, callback }: any, { call, put, select }: any) {
            try {
                const response: Promise<any> = yield call(createProductList, payload)
                const existingProductLists: Promise<any> = yield select((state: any) => state.search.productLists)

                let newProductLists = [...(existingProductLists as any), {
                    id: (response as any).listId,
                    name: payload.name,
                    created_at: new Date().toISOString(),
                }]

                // sort the product lists by created_at
                newProductLists = newProductLists.sort((a: any, b: any) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())

                yield put({
                    type: 'setProductLists',
                    payload: newProductLists
                })
                if (callback) callback()

            } catch (error: any) {
                if (callback) callback()
                message.error(error.response.data.message)
            }

        },
        *addProductsToList({ payload, callback }: any, { call }: any) {
            const response: Promise<any> = yield call(addProductsToList, payload)
            if (callback) callback(response)
        }
    },
    reducers: {
        updateState(state: any, { payload }: any) {
            return {
                ...state,
                ...payload
            }
        },
        setProducts(state: any, { payload }: any) {
            return {
                ...state,
                productsData: payload
            }
        },
        setProductLists(state: any, { payload }: any) {
            return {
                ...state,
                productLists: payload,
            };
        },
        addToSelectedRows(state: any, { payload }: any) {
            return {
                ...state,
                selectedRows: [...state.selectedRows, payload]
            }
        },
        removeFromSelectedRows(state: any, { payload }: any) {
            return {
                ...state,
                selectedRows: state.selectedRows.filter((row: any) => row !== payload)
            }
        },
        addAllProductsToSelectedRows(state: any) {
            return {
                ...state,
                selectedRows: state.productsData.data.map((_: any, index: number) => index)
            }
        },
        clearSelectedRows(state: any) {
            return {
                ...state,
                selectedRows: []
            }
        },
        setIngredientSuggestions(state: any, { payload }: any) {
            return {
                ...state,
                ingredientSuggestions: payload
            }
        },
        resetIngredientSuggestion(state: any) {
            return {
                ...state,
                ingredientSuggestions: []
            }
        },
        updateSearchValue(state: any, { payload }: any) {
            return {
                ...state,
                searchValue: payload
            };
        },
        updateFilters(state: any, { payload }: any) {
            // Clean filters before updating the state
            const cleanedFilters = Object.entries(payload).reduce((acc: any, [key, value]) => {
                if (value) acc[key] = value; // Only keep non-empty values
                return acc;
            }, {});
            
            return {
                ...state,
                filters: cleanedFilters
            }
        },
        resetFilters(state: any) {
            return {
                ...state,
                filters: {}
            }
        },
        toggleFilterDrawer(state: any) {
            return {
                ...state,
                filterDrawerVisible: !state.filterDrawerVisible
            }
        },
        toggleListModal(state: any) {
            return {
                ...state,
                listModalVisible: !state.listModalVisible
            }
        }
    }
}

export default Model